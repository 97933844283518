<template>
  <div class="bank-account-card">
    <div class="bank-account-card__info">
      <Guard permission="bankAccounts.setDefault" v-slot="{ isAvailable }">
        <Checkbox
          :value="isDefault"
          @input="!isDefault && isAvailable && $emit('make-default', $event)"
        >
          {{
            isAvailable ? "Set as default bank account" : "Default bank account"
          }}
        </Checkbox>
      </Guard>
      <p class="bank-account-card__info__title bolder">
        {{ title }}
      </p>
      <div class="bank-account-card__info__item">
        <span>Account holder</span>
        <span class="bolder">{{ holder || "-" }}</span>
      </div>
      <div class="bank-account-card__info__item">
        <span>Account country</span>
        <span class="bolder">{{ countryName || "-" }}</span>
      </div>
      <div class="bank-account-card__info__item">
        <span>Last 4 of account number</span>
        <span class="bolder">{{ numberLast4 || "-" }}</span>
      </div>
      <div class="bank-account-card__info__item">
        <span>Routing number</span>
        <span class="bolder">{{ routingNumber || "-" }}</span>
      </div>
    </div>
    <Guard permission="bankAccounts.delete" v-slot="{ isAvailable }">
      <Button
        v-if="isAvailable && !isDefault"
        class="bank-account-card__button"
        :is-block="['xs', 'sm'].includes($mq)"
        isOutlined
        isSmall
        variant="danger"
        @click="$emit('remove')"
      >
        Remove
      </Button>
    </Guard>
  </div>
</template>

<script>
import { getCountry } from "countries-and-timezones";
import Guard from "@/components/common/Guard";

export default {
  name: "BankAccountCard",
  components: { Guard },
  props: {
    title: {
      type: String,
      default: "",
    },
    holder: {
      type: String,
      default: "-",
    },
    country: {
      type: String,
      default: "-",
    },
    numberLast4: {
      type: String,
      default: "-",
    },
    routingNumber: {
      type: String,
      default: "-",
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    countryName() {
      return getCountry(this.country).name;
    },
  },
};
</script>

<style lang="scss" scoped>
.bank-account-card {
  padding: 24px 16px;
  border-radius: 8px;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-500;

    .bolder {
      font-weight: 500;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}
</style>
