<template>
  <div class="bank-accounts-page">
    <BackTitle @click="goBack">Bank accounts</BackTitle>
    <Guard permission="bankAccounts.create" v-slot="{ isAvailable }">
      <IconButton v-if="isAvailable" icon="plus" isRaised @click="goToCreation">
        Add bank account
      </IconButton>
    </Guard>
    <Loader v-if="isLoading" size="m" color="primary" class="mx-auto" />
    <div v-else-if="!bankAccounts.length" class="empty">
      You haven't created bank accounts yet
    </div>
    <div v-else class="bank-accounts-page__list">
      <BankAccountCard
        v-for="item in bankAccounts"
        :key="item.id"
        :title="item.bank_name"
        :holder="item.account_holder_name"
        :country="item.country"
        :number-last4="item.last4"
        :routing-number="item.routing_number"
        :is-default="item.default_for_currency"
        @remove="removeBankAccount(item)"
        @make-default="makeBankAccountDefault(item, $event)"
      />
    </div>
  </div>
</template>
<script>
import BankAccountCard from "@/components/bank-accounts/BankAccountCard";
import { mapActions, mapState } from "vuex";
import BackTitle from "@/components/common/BackTitle";
import dialog from "@/plugins/dialog";
import BankAccountsService from "@/api/services/BankAccountsService";
import Guard from "@/components/common/Guard";

export default {
  name: "BankAccountsPage",
  components: { Guard, BackTitle, BankAccountCard },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      bankAccounts: (state) =>
        state.bankAccounts.list.sort((bankAccount) => {
          return bankAccount.default_for_currency ? -1 : 1;
        }),
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchBankAccounts();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchBankAccounts: "bankAccounts/fetchBankAccounts",
    }),
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    goToCreation() {
      this.$router.push({
        name: "CreateBankAccount",
      });
    },
    async removeBankAccount(bankAccount) {
      const confirmed = await dialog.confirm({
        title: "Delete Bank Account?",
        message: "All bank account data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isLoading = true;
          await BankAccountsService.deleteBankAccount(bankAccount.id);
          await this.fetchBankAccounts();
        } finally {
          this.isLoading = false;
        }
      }
    },
    async makeBankAccountDefault(bankAccount, isDefault) {
      if (isDefault) {
        const confirmed = await dialog.confirm({
          title: "Are you sure?",
          message:
            "This bank account will become default for its currency and all payouts will go there",
          okText: "Set as default",
          cancelText: "Cancel",
        });
        if (confirmed) {
          try {
            this.isLoading = true;
            bankAccount.default_for_currency = true;
            await BankAccountsService.makeBankAccountDefault(
              bankAccount.id,
              isDefault
            );
            await this.fetchBankAccounts();
          } finally {
            this.isLoading = false;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bank-accounts-page {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: $media-laptop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px 20px;
    }
  }
}
</style>
